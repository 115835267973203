/* App.module.css */

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* App.module.css */

/* Основной контейнер приложения */
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Контейнер для контентных страниц */
.contentContainer {
  overflow: hidden; /* Предотвращаем выход контента за пределы */
  /* margin-top: 70px; */
  height: 100vh;
}

/* Основной контент с возможностью прокрутки */
.mainContent {
  width: 100%;
  height: 100%;
  z-index: 3;
}



/* Стиль для макета с боковым меню */
.layoutWithSidebar {
  display: flex;
  height: 100vh;
  background-color: #f9f9f9;;
}

@media (max-width: 768px) {
  .layoutWithSidebar {
    display: flex;
    height: calc(100vh - 60px);
    margin-top: 60px;
  }
}

