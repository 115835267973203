.loadingOverlay {
    position: fixed; /* фиксированное позиционирование */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* полупрозрачный фон */
    z-index: 100; /* поверх всех остальных элементов */
}

.loadingImage {
    width: 50px; /* Размеры изображения можно настроить по вашему усмотрению */
    height: 50px;
    background-image: url('../../assets/icons/loading.gif'); /* Путь к вашей анимированной gif или SVG */
    background-size: cover;
}
