body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Блок с контентом, где нужна прокрутка */
.scrollable {
  height: 100%; /* Высота 100% от родителя */
  overflow-y: auto; /* Вертикальная прокрутка */
  margin: 0;
  scroll-behavior: smooth; /* Плавная прокрутка */
}

/* Кастомная полоса прокрутки */
.scrollable::-webkit-scrollbar {
  width: 12px; /* Ширина полосы */
}

.scrollable::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(70, 130, 180, 0.7), rgba(123, 104, 238, 0.7)); /* Градиент синего и светло-фиолетового */
  border-radius: 15px; /* Максимально закругленные края */
  border: 2px solid transparent; /* Прозрачная граница для размытости */
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.4), inset 0 0 10px rgba(123, 104, 238, 0.4); /* Внешнее и внутреннее размытие */
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgba(65, 105, 225, 0.9), rgba(148, 0, 211, 0.9)); /* Более насыщенный градиент при наведении */
}

/* Горизонтальная полоса прокрутки */
.scrollable::-webkit-scrollbar-horizontal {
  height: 12px; /* Симметрия с вертикальной полосой */
}

.scrollable::-webkit-scrollbar-thumb-horizontal {
  background: linear-gradient(90deg, rgba(70, 130, 180, 0.7), rgba(123, 104, 238, 0.7)); /* Горизонтальный градиент */
  border-radius: 15px;
  border: 2px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.4), inset 0 0 10px rgba(123, 104, 238, 0.4);
}

.scrollable::-webkit-scrollbar-thumb-horizontal:hover {
  background: linear-gradient(90deg, rgba(65, 105, 225, 0.9), rgba(148, 0, 211, 0.9)); /* При наведении на горизонтальную полосу */
}

/* Дополнительная настройка для Firefox */
.scrollable {
  scrollbar-width: thin; /* Тонкие полосы прокрутки */
  scrollbar-color: rgba(70, 130, 180, 0.7) rgba(240, 248, 255, 0.7); /* Цвет прокрутки и фона */
}

/* Плавный переход всех изменений при наведении */
.scrollable::-webkit-scrollbar-thumb, .scrollable::-webkit-scrollbar-thumb-horizontal {
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

/* Адаптация для Retina-дисплеев */
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .scrollable::-webkit-scrollbar {
    width: 10px; /* Уменьшение ширины на экранах Retina */
  }

  .scrollable::-webkit-scrollbar-thumb {
    border-width: 1px;
  }
}


/* Общие стили для всех таблиц */
table {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  border-collapse: separate; /* Отдельные границы */
  border-spacing: 0; /* Убираем отступы между ячейками */
  overflow-y: auto;
}

/* Заголовки таблиц */
thead th {
  font-weight: bold;
  text-align: left;
  padding: 10px 15px;
  background: #EDEDED; /* Светло-серый фон для заголовков */
  font-size: 14px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; /* Добавляем нижнюю границу */
}

/* Первая и последняя ячейки строки */
thead tr th:first-child, tbody tr td:first-child {
  border-left: 1px solid #ddd; /* Левая граница первой ячейки */
}

thead tr th:last-child, tbody tr td:last-child {
  border-right: 1px solid #ddd; /* Правая граница последней ячейки */
}

/* Радиус углов для первой и последней ячейки заголовка */
thead tr th:first-child {
  border-radius: 20px 0 0 0;
}

thead tr th:last-child {
  border-radius: 0 20px 0 0;
}

/* Ячейки таблицы */
tbody td {
  text-align: left;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top; /* Выравнивание по верхнему краю */
}

/* Чередование фона для строк */
tbody tr:nth-child(even) {
  background-color: #F8F8F8; /* Светло-серый для четных строк */
}

/* Нижняя граница для последней строки */
tbody tr:last-child td {
  border-bottom: 1px solid #ddd;
}

/* Радиус углов для последней строки */
tbody tr:last-child td:first-child {
  border-radius: 0 0 0 20px;
}

tbody tr:last-child td:last-child {
  border-radius: 0 0 20px 0;
}

/* Адаптивность для небольших экранов */
@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap; /* Предотвращаем перенос текста внутри ячеек */
  }

  thead th, tbody td {
    padding: 8px 10px;
    font-size: 13px; /* Уменьшаем размер шрифта */
  }
}

form {
  width: 100%;
}